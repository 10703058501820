import { Box, Link, Typography } from '@mui/material';
import { theme } from '@care/material-ui-theme';
import { AmplitudeEventName, logAnalyticsEvent } from '@/utils/AnalyticsHelper';
import { PAGE_PROPERTIES } from '@/constants';

const PartnerBanner = () => {
  const handlePhoneClick = () => {
    logAnalyticsEvent({
      data: {
        cta_location: 'Partners - banner',
        cta_text: 'Call for a complimentary consultation',
      },
      name: AmplitudeEventName.ctaInteracted,
      pageProperties: PAGE_PROPERTIES.partners,
    });
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        background: theme.palette.care?.red[100],
        display: 'flex',
        justifyContent: 'center',
        minHeight: '41px',
        textAlign: 'center',
      }}
      data-testid="partner-banner">
      <Typography variant="body3">
        Call for a complimentary consultation
        <Link
          sx={{
            margin: theme.spacing(0, 0.5),
            fontWeight: 700,
          }}
          data-testid="phone-number-link"
          onClick={handlePhoneClick}
          href="tel:877-367-1969"
          rel="nofollow">
          (877) 367-1969
        </Link>
      </Typography>
    </Box>
  );
};

export default PartnerBanner;
