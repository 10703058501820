import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import getConfig from 'next/config';
import { IconLogoHomepaylogo } from '@care/react-icons';
import { HomePayVisitorHeaderLink } from '@care/navigation/dist/components/types';
import { NextConfig } from '@/interfaces';
import { PAGE_PROPERTIES, ROUTING_PROPERTIES } from '@/constants';
import Header from '@/components/Layout/Header';

const { publicRuntimeConfig } = getConfig() as NextConfig;
const { PARTNERS_URL, HOMEPAY_URL } = publicRuntimeConfig;

const PartnersHeader: FC = () => {
  const isMobile = useMediaQuery('(max-width: 974px)');

  return (
    <Header
      hidePhone
      branding={{
        logo: <IconLogoHomepaylogo height="58px" width="200px" />,
        href: `${PARTNERS_URL}/homepay`,
      }}
      isMobile={isMobile}
      leftLinks={[
        {
          text: 'Home',
          href: `${PARTNERS_URL}/homepay`,
          type: 'link',
          isVisible: isMobile,
        },
        {
          text: 'Service & Pricing',
          href: `#service_pricing`,
          type: 'link',
        },
        {
          text: 'Service Guarantee',
          href: `#service_guarantee`,
          type: 'link',
        },
        {
          text: 'Benefits for Employees',
          href: `#benefits_for_employees`,
          type: 'link',
        },

        {
          text: 'ProPortal',
          href: `${HOMEPAY_URL}${ROUTING_PROPERTIES.proPortal.relativeURL}`,
          type: 'link',
        } as HomePayVisitorHeaderLink,
      ]}
      pageProperties={PAGE_PROPERTIES.partners}
    />
  );
};

export default PartnersHeader;
